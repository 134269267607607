import { motion } from 'framer-motion';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import logo from '../../../../images/nuborrow_logomark.svg';
import { MotionBasicCard } from '../../../basic-card';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import ParallaxBackground from '../../../parallax-background';
import * as style from './index.module.scss';

export default function RefinanceFeatures() {
  const data: FeatureSectionProps = {
    items: [
      {
        title: 'Renovate and save money.',
        description: `Taking advantage of lower rates to save more, and upgrading your home is a good move. Adding a little extra cash in the process can turn good into great!`,
        art: renovateArt(),
        actions: [{ label: `Start Picking Out Paint`, href: 'https://apply.nuborrow.com', isPrimary: true }]
      }
    ]
  };

  return <FeatureSection {...data} />;
}

export const renovateArt = () => {
  const [containerRef, inView] = useInView({ threshold: 0.15 });

  const data: { title: string; text: string }[] = [
    {
      title: 'Rates Are Still Low',
      text: 'Refinance up to 80% LTV'
    }
  ];

  const variants = {
    visible: {
      opacity: 1,
      transition: { duration: 0.1, staggerChildren: 0.2 }
    },
    invisible: {
      opacity: 0.98
    }
  };

  const childVariants = {
    visible: {
      opacity: 1,
      translateY: '0%',
      transition: { duration: 0.4 }
    },
    invisible: {
      opacity: 1,
      translateY: '50%'
    }
  };

  let animation = inView ? 'visible' : 'invisible';

  return (
    <div
      className={style.wrapper + ' d-flex flex-column align-items-center justify-content-center'}
    >
      <ParallaxBackground className={style.heroImage} />
      <motion.div
        ref={containerRef}
        className={style.cards}
        variants={variants}
        animate={animation}
      >
        {data.map((item) => (
          <MotionBasicCard
            key={`${item.title} ${item.text}`}
            className={style.card + ' d-flex'}
            variants={childVariants}
          >
            <div className={style.logo}>
              <img src={logo} alt="Nuborrow" />
            </div>
            <div className={style.details + ' d-flex flex-column justify-content-center'}>
              <h3>{item.title}</h3>
              <h5>{item.text}</h5>
            </div>
          </MotionBasicCard>
        ))}
      </motion.div>
    </div>
  );
};
