import React, { useState } from 'react';
import CallToAction from '../components/call-to-action';
import { CallToActionButton, CallToActionCardProps } from '../components/call-to-action/models';
import Layout from '../components/layout';
import RateBanner, { RateBannerProps } from '../components/rate-banner';
import RateComparison, {
  RateComparisonItem,
  RateComparisonProps
} from '../components/rate-comparison';
import RefinanceFeatures from '../components/sections/refinance/features';
import NoFeeGuarantee from '../components/sections/refinance/no-fee-guarantee';
import CreditScore from '../components/sections/shared/credit-score';
import GoodbyDebt from '../components/sections/shared/goodbye-debt';
import Refinance from '../components/sections/shared/refinance';
import TrackingPixel from '../components/tracking-pixel';
import Nuborrow from '../images/nuborrow_logo.svg';
import CMLSFinancial from '../images/optimized/logos_cmls_financial.svg';
import TDBank from '../images/optimized/logos_td_bank.svg';
import { RootState } from '../utils/root-state';

export default function RefinancePage() {
  const callToActionCards: CallToActionCardProps[] = [
    {
      title: 'Why waving conditions can be dangerous in any market.',
      actionLabel: 'Traps you must avoid',
      actionHref: ''
    },
    {
      title: 'How will the bank approve my application?',
      actionLabel: 'Why location matters',
      actionHref: ''
    },
    {
      title: 'Does a pre-approval mean we are approved?',
      actionLabel: 'Avoid stressful delays',
      actionHref: ''
    },
    {
      title: 'Do we really need all of these documents?',
      actionLabel: 'Documents you need',
      actionHref: ''
    },
    {
      title: 'Match me with an agent.',
      actionLabel: 'Real Estate',
      actionHref: 'https://apply.nuborrow.com'
    },
    {
      title: 'What should I do?',
      actionLabel: 'Get home advice',
      actionHref: '/learn'
    }
  ];

  const ctaButtons: CallToActionButton[] = [
    {
      label: 'Get Started',
      href: 'https://apply.nuborrow.com',
      isPrimary: true
    },
    {
      label: 'Give us a call',
      href: '/about/#contactus'
    }
  ];

  const ctaTitle = <>We've helped THOUSANDS of homeowners save MILLIONS.</>;

  const [rate, setRate] = useState(0);
  const [effectiveDate, setEffectiveDate] = useState(new Date(1970, 0));

  const rateProps: RateBannerProps = {
    title: 'Save thousands with a lower mortgage payment.',
    rate,
    description: (
      <>
        <span>
          When you save, we both win! Since 2014 we've saved our clients MILLIONS with our
          commitment to finding you the lowest refinancing rates on the open market.
        </span>
      </>
    ),
    // slideButtonItems: [
    //   {
    //     label: `Today's Rate`,
    //     action: () => setRate(2.59)
    //   },
    //   {
    //     label: `View APR`,
    //     action: () => setRate(3.1415)
    //   }
    // ],
    actionButtonItems: [
      {
        label: 'Get Started',
        href: 'https://products.nuborrow.com/#refinance_basic_detail',
        isPrimary: true
      }
    ]
  };

  const [rateComparisonItems, setRateComparisonItems] = useState<RateComparisonItem[]>([
    {
      logo: Nuborrow,
      primary: {
        highlight: '1.44%',
        label: 'Variable Rate'
      },
      secondary: {
        highlight: '2.04%',
        label: 'Fixed Rate'
      }
    },
    {
      logo: TDBank,
      primary: {
        highlight: '1.44%',
        label: 'Variable Rate'
      },
      secondary: {
        highlight: '2.04%',
        label: 'Fixed Rate'
      }
    },
    {
      logo: CMLSFinancial,
      primary: {
        highlight: '1.44%',
        label: 'Variable Rate'
      },
      secondary: {
        highlight: '2.04%',
        label: 'Fixed Rate'
      }
    }
  ]);

  const rateComparison: RateComparisonProps = {
    title: `Today's best 5 years variable and fixed rates.`,
    items: rateComparisonItems,
    effectiveDate: effectiveDate
  };

  const handleState = (state: RootState) => {
    setRate(state.findLowestRateForProduct('Refinancing')?.variable_rate ?? 0);

    if (!state.productRates.Refinancing) {
      return;
    }

    const list = state.findLowestRatesForProduct('Refinancing', 3);
    const items: RateComparisonItem[] = list.map((bank) => ({
      logo: state.getLogoForBank(bank.name),
      primary: {
        highlight: `${bank.variable_rate}%`,
        label: 'Variable Rate'
      },
      secondary: {
        highlight: `${bank.rate}%`,
        label: 'Fixed Rate'
      }
    }));

    setEffectiveDate(new Date(state.productRates.Refinancing.effectiveDate));
    setRateComparisonItems(items);
  };

  return (
    <Layout pageTitle={'Refinance'} onRootState={handleState}>
      <RateBanner {...rateProps} />
      <RateComparison {...rateComparison} />
      <Refinance title={'Click apply and start saving.'} />
      <GoodbyDebt />
      <RefinanceFeatures />
      <CreditScore />
      <NoFeeGuarantee />
      <CallToAction title={ctaTitle} cards={callToActionCards} actions={ctaButtons} />

      <TrackingPixel src="https://geo-tracker.ads.thinkmidbrain.com/hyperad/pixel-tracking?order=112933&action=landing" />
    </Layout>
  );
}
