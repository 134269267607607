import React from 'react';
import AnimatedCounter from '../../../animated-counter';
import LottieAnimation from '../../../animation';
import meterAnim from '../../../animation/animations/price-ring.json';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import * as style from './index.module.scss';

export default function Refinance(props: { title: JSX.Element | string; fullLength?: boolean }) {
  const data: FeatureSectionProps = {
    title: props.title,
    fullLengthTitle: props.fullLength,
    items: [
      {
        align: 'left',
        title: `Lower your \nmonthly payments.`,
        subtitle: 'Refinance with Nuborrow',
        description: `Lower your rate. Change your term. Take cash out to pay off debt. No matter which option you choose, refinancing with Nuborrow can save you money.`,
        actions: [{ label: `Let's Start Saving`, href: 'https://apply.nuborrow.com', isPrimary: true }],
        art: refinanceArt()
      }
    ]
  };

  return <FeatureSection {...data} />;
}

export const refinanceArt = () => {
  return (
    <div className={`${style.refinanceWrapper} my-4 d-flex flex-column justify-content-center`}>
      <div className={`${style.chart} `}>
        <LottieAnimation animation={meterAnim} threshold={0.42} className={style.animation} />
        <div
          className={`${style.content} d-flex flex-column align-items-center justify-content-center`}
        >
          <AnimatedCounter className={style.counter} value={16800} prefix={'$'} />
          <h3>average yearly savings</h3>
          <p>
            based on mortgage refinancing and including other higher interest debt, savings will
            vary based on your situation.
          </p>
        </div>
      </div>
    </div>
  );
};
