import React from 'react';
import LottieAnimation from '../../../animation';
import chart from '../../../animation/animations/animated-chart.json';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';

export default function GoodbyDebt() {
  const data: FeatureSectionProps = {
    items: [
      {
        title: 'Break-up with Debt.',
        subtitle: 'Erase high-interest debt',
        description: (
          <>
            <span>
              Why keep paying credit card rates of 19.99% when mortgage rates remain at historic
              lows?
              <br />
              <br />
              Take advantage of lower rates and refinance your mortgage with some extra cashback to
              pay off debt, renovate or invest.
            </span>
          </>
        ),
        art: goodbyeDebtArt(),
        actions: [{ label: `Yes, Unlock My Equity`, href: 'https://apply.nuborrow.com', isPrimary: true }]
      }
    ]
  };

  return <FeatureSection {...data} />;
}

export const goodbyeDebtArt = () => {
  return (
    <div className={' d-flex flex-column align-items-center justify-content-center'}>
      <div className={''}>
        <LottieAnimation animation={chart} threshold={0.2} className={''} />
      </div>
    </div>
  );
};
