import React from 'react';
import AnimatedCounter from '../../../animated-counter';
import LottieAnimation from '../../../animation';
import scoreAnim from '../../../animation/animations/score-meter.json';
import PerspectiveCard from '../../../perspective-card';
import RoundBtn from '../../../round-btn';
import { MotionSectionDescription } from '../../../section-description';
import SectionTitle from '../../../section-title';
import * as style from './index.module.scss';

export default function CreditScore() {
  return (
    <section className={style.creditScore + ' overflow-hidden position-relative m-auto'}>
      <div className="container position-relative h-100 d-flex flex-column align-items-center justify-content-center">
        <div
          className={
            style.creditContent +
            ' d-flex flex-column align-items-center justify-content-center text-center'
          }
        >
          <div className={style.animationWrapper + ' col-12 col-sm-8 col-md-6'}>
            <LottieAnimation animation={scoreAnim} threshold={0.42} className={''} />
            <div
              className={`${style.content} d-flex flex-column align-items-center justify-content-center`}
            >
              <AnimatedCounter className={style.counter} value={690} />
            </div>
          </div>
          <SectionTitle className={style.title + ' mb-4'}>
            Get your free credit score and shop worry-free.
          </SectionTitle>
          <MotionSectionDescription className={style.desc + ' mx-lg-4'}>
            Your credit is an important piece of the buying process. Nuborrow will arm you with your
            free credit score, and a detailed breakdown of how to further boost your score to help
            make the home buying process less stressful.
          </MotionSectionDescription>
          <PerspectiveCard className={'col-10 col-sm-8 col-md-6 col-lg-4'} intensity={25}>
            <RoundBtn className={style.primaryBtn} href="https://apply.nuborrow.com">
              Get Qualified
            </RoundBtn>
          </PerspectiveCard>
        </div>
      </div>
    </section>
  );
}
