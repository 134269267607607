import React from 'react';
import PerspectiveCard from '../../../perspective-card';
import RoundBtn from '../../../round-btn';
import SectionTitle from '../../../section-title';
import * as style from './index.module.scss';

export default function NoFeeGuarantee() {
  return (
    <section className={style.noFee + ' overflow-hidden position-relative m-auto'}>
      <div className="container-fluid position-relative h-100 d-flex flex-column align-items-center justify-content-center">
        <div className={style.titleContainer + ' text-center'}>
          <SectionTitle className={' mb-3 mb-md-4'}>No Fees, No Kidding</SectionTitle>
        </div>
        <div className={style.coloredBlock}>
          <div className="col-10 offset-1 col-lg-6 offset-lg-3">
            <p>
              When you refinance your mortgage with one of our Canadian banking partners, you will
              not pay a broker fee — ever.
            </p>

            <p>
              We call it our Nuborrow <b>*No Fee Guarantee</b>. This is our commitment to
              transparency, and above all, our dedication to saving homeowners every penny we
              possibly can.{' '}
            </p>

            <p>
              You can expect to save on your rate when you switch banks, but we believe you should
              save on closing costs too! At Nuborrow, if we find you a new bank-approved mortgage
              option for a lower rate, you will not pay a fee.
            </p>

            <p>
              And yeah, we stand behind our 'No Fee Guarantee' for as long as you are a part of our
              Nuborrow family.
            </p>

            <p>
              <small>
                <small>
                  <small>
                    *Terms and Conditions apply. Talk to a team member to discover how to qualify for
                    this program.
                  </small>
                </small>
              </small>
            </p>

            {/* <PerspectiveCard className={'col-6'} intensity={25}>
              <RoundBtn className={style.primaryBtn}>I Want In</RoundBtn>
            </PerspectiveCard> */}
          </div>
        </div>
      </div>
    </section>
  );
}
